// 公用api
import request from '@/tools/request';

// 获取插件状态
export function getInstallStatus(params, loading) {
  return request({
    url: `/imagesearch-index-default.html`,
    method: 'get',
    params,
    loading,
  });
}
//插件卸载
export const uninstall = loading => {
  return request({
    url: '/imagesearch-index-del.html',
    loading,
  });
};
//插件安装
export const install = loading => {
  return request({
    url: '/imagesearch-index-set.html',
    loading,
  });
};
