<template>
  <div class="home">
    <header>
      <m-page-header title="操作指南"></m-page-header>
    </header>
    <main>
      <m-form-skeleton :loading="loading" type="text">
        <div class="description">
          <ul>
            <li class="title">
              <h5>操作指南：</h5>
            </li>
            <li>1.请设置搜图图标及其他相关配置</li>
            <li>2.商品列表可查看商品同步状况</li>
            <li>3.搜索历史可查看用户搜索图片历史记录，数据统计可查看搜图次数</li>
          </ul>
        </div>
        <div class="images">
          <m-image-preview
            :imgList="[homePreview1, homePreview2, homePreview3, homePreview4, homePreview5]"
          />
        </div>
        <div class="btn">
          <el-button
            :type="isInstall ? 'danger' : 'primary'"
            size="medium"
            @click="setPlugin"
            :loading="btnLoading"
          >
            {{ isInstall ? '卸载插件' : '安装插件' }}
          </el-button>
        </div>
      </m-form-skeleton>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';
import { getInstallStatus, install, uninstall } from '../api/Home';

import homePreview1 from '../statics/img/home1.jpg';
import homePreview2 from '../statics/img/home2.jpg';
import homePreview3 from '../statics/img/home3.jpg';
import homePreview4 from '../statics/img/home4.jpg';
import homePreview5 from '../statics/img/home5.jpg';
import { ElMessage } from 'element-plus';
export default {
  name: 'Home',
  components: {
    'm-image-preview': MImagePreview,
  },
  setup() {
    const loading = ref(false);
    const isInstall = ref(false); // 是否已安装
    const btnLoading = ref(false);

    // 获取安装状态
    const setInstallStatus = async () => {
      const { data, status } = await getInstallStatus({}, loading);

      if (!status) return;
      isInstall.value = data.status == '1' ? true : false;
    };

    // 卸载 / 安装 插件
    const setPlugin = async () => {
      if (isInstall.value) {
        const res = await uninstall(btnLoading);
        if (!res.status) return;

        ElMessage.success('卸载成功');
        isInstall.value = !isInstall.value;
      } else {
        const res = await install(btnLoading);
        if (!res.status) return;
        ElMessage.success('安装成功');
        isInstall.value = !isInstall.value;
      }
    };
    return {
      homePreview1,
      homePreview2,
      homePreview3,
      homePreview4,
      homePreview5,
      setInstallStatus,
      setPlugin,
      isInstall,
      btnLoading,
      loading,
    };
  },
  mounted() {
    this.setInstallStatus();
  },
};
</script>

<style lang="scss" scoped>
main {
  padding: 20px;
  .description {
    ul {
      li {
        padding: 8px 0;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px 0;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
